<template>
  <div class="p-4 sm:p-16">
    <h2>CILO</h2>
    <br />
    <p>
      CILO regards in high importance the ​​basic privacy rights, and believes
      that these basic rights should not differ depending on where you live in
      the world. That’s why we treat any data that relates to an identified or
      identifiable individual or that is linked or linkable to them by CILO as
      “personal data,” no matter where the individual lives. This means that
      data that directly identifies you — such as your name — is personal data,
      and also data that does not directly identify you, but that can reasonably
      be used to identify you — such as the serial number of your device — is
      personal data. Aggregated data is considered non-personal data for the
      purposes of this Privacy Policy. This Privacy Policy (**Policy**)
      describes how we at CILO Global Corp. collect, use, share, and secure
      your Personal Information when you visit the CILO Platform
      (https://cilo.io) (the **Platform**) or create, buy, transfer, or trade
      unique CILO Items in our Market (the **Market**).
    </p>
    <br />
    <p>
      Art is worthwhile. I feel the art value that I take for granted. They ask,
      "How does art value be embraced by the real and financial economies?"
      Today, art is worthwhile when it shakes existing systems that have been
      materialized. Art should create a new perspective and revolutionary
      discourse. So the value of art increases as it exists outside the physical
      and financial economies. The original nature of art cannot be embraced by
      the existing economic system.
    </p>

    <br />
    <p>
      A work of art is based on complexity. Artworks accept the complex at hand
      of the times. Artwork that captures the spirit of the times is another
      complex, condensed into tension and contradiction. The paradox of the
      fluctuating kinetic energy in a stationary artwork is the basis of art.
      The value of art is expressed when it influences the world and makes
      changes in the world through works of art.
    </p>

    <br />
    <p>
      The nature of art, the spectacle of art, is created when everyone shares
      and owns it. Ninety-nine percent of the world's artists disappear without
      ever showing their works. Only about 5% of the art are available to the
      public for trading. Art information, art values, and works of art are
      selected, shared and measured according to market power and social (and
      political) power. Art is invested by a few as assets, and art trade is
      limited to some owners. However, many artists who are creators are
      excluded from the deal. Art works are also amplified through easy exchange
      and fast circulation. Existing currencies are limited to the measurement
      of the value of use or exchange of goods, so cannot value works of art. We
      need a new currency that fits the trade of works of art.
    </p>

    <br />
    <p>
      CILO is a new system for art. It realizes the art value and art trade
      according to the nature of the art through blockchain. It consists of a
      blockchain-based platform that shares art information and trades art
      works, blockchain currency that fits art values, and blockchain-based
      tokens that guarantee artists' creative costs and rights to work. CILO
      NFT Marketplace, CILO STO Exchange, and CILO Art Cryptocurrency are
      about to be developed and realized. CILO:
    </p>

    <br />
    <p>
      CILO is a migratory bird that lives about 10 centimeters in North
      America. Because of its beautiful color and balanced coloring, it has
      often appeared in art works by local artists. In winter, it moves to
      Central America or the West Indies, and returns in following spring. Small
      gestures naturally help pollinate plants and unintentionally transport
      plant seeds or insect eggs between continents, contributing to animal and
      plant productivity and diversity. In &lt;The Fables of Honeybees&gt; by
      Bernard Mandevillem, ‘Honeybees’ represent “contributed capital” that was
      not covered by the economic system in the past, and CILO represent “art
      capital” and “cognitive capital” that the existing economic system does
      not consider.
    </p>
    <br />
    <p>
      <!-- CILO Slogan:
      <br />
      <br />
      Create, Tune and Drop -->
      <br />
      <br />
      The CILO slogan "Create, Tune and Drop" is a phrase that includes
      creating of artists, tuning of art as a service of parula, and dropping of
      art in blockchain world. The context of the slogan is borrowed from
      Timothy Leary's famous phrase "Turn on, tune in and drop out."
    </p>

    <br />
    <br />
    <br />
    <!-- <h2>Our Team</h2>
    <div
      class="
      about__layout
      grid-cols-1
      sm:grid-cols-2
      md:grid-cols-3
      lg:grid-cols-4
      xxl:grid-cols-5
      mt-20
      "
    >
      <article>
        <div><img src="@/assets/img/about/Seohee.png" alt="Seohee" /></div>
        <div class="flex flex-col items-center">
          <p>Seohee</p>
          <p>CEO & Co-founder</p>
        </div>
      </article>

      <article>
        <div><img src="@/assets/img/about/Sungik.png" alt="Sungik" /></div>
        <div class="flex flex-col items-center">
          <p>Sean Sungik</p>
          <p>CCO & Co-founder</p>
        </div>
      </article>

      <article>
        <div><img src="@/assets/img/about/John.png" alt="John" /></div>
        <div class="flex flex-col items-center">
          <p>John</p>
          <p>COO & Co-founder</p>
        </div>
      </article>

      <article>
        <div><img src="@/assets/img/about/Aiden.png" alt="Aiden" /></div>
        <div class="flex flex-col items-center">
          <p>Aiden</p>
          <p>CTO</p>
        </div>
      </article>

      <article>
        <div><img src="@/assets/img/about/Jade.png" alt="Jade" /></div>
        <div class="flex flex-col items-center">
          <p>Jade</p>
          <p>Developer</p>
        </div>
      </article>

      <article>
        <div><img src="@/assets/img/about/Sienna.png" alt="Sienna" /></div>
        <div class="flex flex-col items-center">
          <p>Sienna</p>
          <p>Developer</p>
        </div>
      </article>

      <article>
        <div><img src="@/assets/img/about/Haidi.png" alt="Haidi" /></div>
        <div class="flex flex-col items-center">
          <p>Haidi</p>
          <p>Designer</p>
        </div>
      </article>

      <article>
        <div><img src="@/assets/img/about/Thomas.png" alt="Thomas" /></div>
        <div class="flex flex-col items-center">
          <p>Thomas</p>
          <p>Developer</p>
        </div>
      </article>

      <article>
        <div><img src="@/assets/img/about/Lemon.png" alt="Lemon" /></div>
        <div class="flex flex-col items-center">
          <p>Lemon</p>
          <p>Developer</p>
        </div>
      </article>

      <article>
        <div><img src="@/assets/img/about/Mahns.png" alt="Mahns" /></div>
        <div class="flex flex-col items-center">
          <p>Mahns</p>
          <p>Developer</p>
        </div>
      </article>
    </div> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
@import "@/scss/_text.scss";

@include less-than-md {
  .about__layout {
    display: grid;
    grid-template-rows: 40vh;
    grid-auto-rows: 40vh;
    gap: 3rem;
    article {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto 3rem;
      div:first-child {
        height: calc(40vh - 4rem);
      }
    }
  }
}

@include lg {
  .about__layout {
    display: grid;
    grid-template-rows: 25vw;
    grid-auto-rows: 25vw;
    gap: 3rem;
    article {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto 3rem;
      div:first-child {
        height: calc(25vw - 4rem);
      }
    }
  }
}

@include more-than-lg {
  .about__layout {
    display: grid;
    grid-template-rows: 35vh;
    grid-auto-rows: 35vh;
    gap: 3rem;
    article {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto 3rem;
      div:first-child {
        height: calc(35vh - 4rem);
      }
    }
  }
}

h2 {
  font-size: 1.4rem;
  font-weight: 600;
}
h3 {
  font-weight: 600;
}
p {
  padding-left: 1rem;
  text-align: justify;
  @extend %text__align;
}
li {
  list-style: circle;
  margin-left: 3rem;
  text-align: justify;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 25%;
}
</style>
